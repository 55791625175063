import React, { useEffect, useState } from 'react';
import { truncate } from '@/store';
import Countdown from './Countdown';

export const MainCard = (props) => {
  const { bg, data } = props;
  const backgroundImage = bg === 'dark' ? `none` : 'none';
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(truncate(data?.price.toString(), 2, 2, 6));
  }, []);

  return (
    <div
      className=" rounded-[24px] flex flex-col p-[18.03px] gap-[18.03px] backdrop-blur-lg border border-whiteBorder"
      style={{
        backgroundImage,
        backgroundColor: bg === 'dark' ? 'transparent' : 'white',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backdropFilter: 'blur(100px)',
        zIndex: 0,
      }}
    >
      <div className="w-[386px] h-[386px] rounded-[18px]">
        {data?.image && (
          <img
            src={data?.image}
            className="w-[386px] h-[386px] rounded-[18px]"
            alt="crypto"
          />
        )}
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col gap-[10.82px]">
          <p
            className=" font-archivo text-[16.23px] leading-[18.5px] font-regular"
            style={
              bg === 'dark'
                ? { color: `rgba(255,255,255,0.3)` }
                : { color: `rgba(14, 14, 14, 0.6)` }
            }
          >
            Current bid
          </p>
          <div className="flex flex-row gap-[9.02px]">
            <img
              src="/svg/CryptoSVG.svg"
              className="w-[28.85px] h-[28.85px]"
              alt="crypto"
            />
            <p className="text-white font-archivo text-[27.05px] leading-[30.83px] font-bold">
              {' '}
              {value} FTN
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-[10.82px] items-end">
          <p
            className="text-whiteBorder font-archivo text-[16.23px] leading-[18.5px] font-regular"
            style={
              bg === 'dark'
                ? { color: `rgba(255,255,255,0.3)` }
                : { color: `rgba(14, 14, 14, 0.6)` }
            }
          >
            Auction end
          </p>
          <div className="flex flex-row gap-[10px]">
            <div
              className="text-white font-archivo text-[27.05px] leading-[30.83px] font-bold"
              style={
                bg === 'dark'
                  ? { color: `rgb(255,255,255)` }
                  : { color: `rgb(14, 14, 14)` }
              }
            >
              {' '}
              {data?.duration && (
                <Countdown timestamp={data?.duration} hourOnly />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
