const listSelector = (state) => state.collection.list;
const singleSelector = (state) => state.collection.single;
const errorSelector = (state) => state.collection.error;
const loadingSelector = (state) => state.collection.loading;
const statisticsSelector = (state) => state.collection.statistics;
const nftsSelector = (state) => state.collection.nfts;
const nftSelector = (state) => state.collection.nft;
const top5ListSelector = (state) => state.collection.top5List;
const nftListSelector = (state) => state.collection.nftList;

export {
  listSelector,
  singleSelector,
  errorSelector,
  loadingSelector,
  statisticsSelector,
  nftsSelector,
  nftSelector,
  top5ListSelector,
  nftListSelector,
};
