const Loader = ({ fullScreen, big }) => {
  if (fullScreen) {
    return (
      <div className="w-full flex justify-center h-full items-center min-h-[80vh]">
        <img alt="loading" src="/svg/loading.svg" className="w-[200px]" />
      </div>
    );
  }

  if (big) {
    return (
      <div className="w-full flex justify-center items-center h-fit">
        <img alt="loading" src="/svg/loading.svg" className="w-[200px]" />
      </div>
    );
  }

  return <img width={50} height={50} src="/svg/loading.svg" alt="loading" />;
};

export default Loader;
