import BidsRows from './rows/BidsRows';
import ConnectionsRows from './rows/ConnectionsRows';
import { useMemo } from 'react';

import Loader from '../Loader';
const Table = ({
  columns,
  data,
  actions,
  mode,
  onRowClick,
  placeholder,
  loading,
}) => {
  const body = useMemo(() => {
    if (loading) {
      return (
        <tbody>
          <tr>
            <td
              colSpan={columns.length + 1}
              className="text-white font-archivo text-[16px] leading-[16px] text-center py-[15px]"
            >
              <div className="w-full flex flex-row justify-center">
                <Loader />
              </div>
            </td>
          </tr>
        </tbody>
      );
    }
    if (data.length === 0) {
      return (
        <tbody>
          <tr>
            <td
              colSpan={columns.length + 1}
              className="text-white font-archivo text-[16px] leading-[16px] text-center py-[30px]"
            >
              {placeholder}
            </td>
          </tr>
        </tbody>
      );
    }
    switch (mode) {
      case 'bids':
        return <BidsRows actions={actions} columns={columns} rows={data} />;
      case 'collections':
        return (
          <ConnectionsRows
            columns={columns}
            rows={data}
            actions={actions}
            onRowClick={onRowClick}
          />
        );
      default:
        return <BidsRows actions={actions} columns={columns} rows={data} />;
    }
  }, [actions, columns, data, mode, loading]);

  const isBidsMode = mode === 'bids';
  const isCollectionMode = mode === 'collections';

  return (
    // <table class={`table-auto w-full ${mode==="bids" && 'border-spacing-y-[20px] border-separate'}`}>
    <table
      className={`table-auto w-full ${
        mode === 'bids' && 'border-spacing-y-[20px] border-separate'
      }`}
    >
      <thead>
        <tr>
          {columns.map((column, index) => (
            <td
              className={`${
                index === 0
                  ? `${
                      isBidsMode || isCollectionMode
                        ? `pl-[${isBidsMode ? '16' : '30'}px]`
                        : ''
                    }`
                  : ''
              } ${
                column.last ? `${isCollectionMode ? `pr-[30px]` : ''}` : ''
              } text-left opacity-[50%] ${
                mode != 'bids' ? 'border-b-[1px] border-[#FFFFFF33]' : ''
              } text-white font-archivo text-[16px] leading-[16px] ${
                mode === 'bids' ? 'py-[16px]' : 'py-[30px]'
              } ${mode != 'bids' && column.last && 'text-right'} `}
              key={index}
            >
              {column.header}
            </td>
          ))}
          {actions && (
            <td
              className={`${
                mode === 'bids' ? 'pl-[16px] ' : ''
              }text-left opacity-[50%] ${
                mode != 'bids' ? 'border-b-[1px] border-[#FFFFFF33]' : ''
              } text-white font-archivo text-[16px] leading-[16px] py-[${
                mode === 'bids' ? 16 : 20
              }px] ${mode != 'bids' && 'text-right'} `}
            >
              Actions
            </td>
          )}
        </tr>
      </thead>
      {body}
    </table>
  );
};

export default Table;
