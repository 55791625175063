import moment from 'moment';
import { getImgUrlFromIPFSHash } from '@/store';

const ConnectionsRows = ({ rows, columns, actions, onRowClick }) => {
  // const columnsLength = columns.length;

  return (
    <tbody>
      {rows.map((row, index) => (
        <tr
          key={index}
          className="cursor-pointer  odd:bg-[#FFFFFF0A] "
          onClick={() => {
            onRowClick?.(row);
          }}
        >
          {columns.map((column, index) => {
            const isInfinityValue = row[[column.accessor]] === '∞';

            const date = moment(Number(row[[column.accessor]]));
            const now = moment();

            const diff = date.diff(now);
            const duration = moment.duration(diff);
            const days = Math.floor(duration.asDays());
            duration.subtract(moment.duration(days, 'days'));
            //Get hours and subtract from duration
            const hours = duration.hours();
            duration.subtract(moment.duration(hours, 'hours'));

            switch (column.type) {
              case 'percent':
                //${row[[column.accessor]]===0?"#FFF":row[[column.accessor]]>0?"#FFFFFA":"#FE7762"}

                return (
                  <td
                    className={`${column.last ? 'text-right' : ''} ${
                      index === 0 ? '' : ''
                    }`}
                    key={index}
                  >
                    <span
                      className={`flex gap-[4px] items-center text-[16px] leading-[17px] font-bold ${
                        row[[column.accessor]] === 0 || isInfinityValue
                          ? 'text-white'
                          : Number(row[[column.accessor]]) > 0
                            ? 'text-[#26DBDC]'
                            : 'text-primery'
                      } font-archivo`}
                    >
                      {isInfinityValue ? (
                        <img
                          className="w-[20px] h-[10px]"
                          src="/png/logo.png"
                          alt=""
                        />
                      ) : (
                        row[[column.accessor]]
                      )}
                      %
                    </span>
                  </td>
                );
              case 'user':
                return (
                  <td
                    className={`${column.last ? 'text-right' : ''} ${
                      index === 0 ? ' ' : ''
                    } py-[10px] pl-[20px]`}
                    key={index}
                  >
                    <div className="flex flex-row gap-[22px] items-center">
                      <img
                        src={
                          getImgUrlFromIPFSHash(row[[column.accessor]].image) ||
                          '/png/empty.png'
                        }
                        alt=""
                        className="w-[60px] h-[60px] rounded-[12px]"
                      />
                      <span className="text-[16px] leading-[17px] font-bold text-white font-archivo">
                        {row[[column.accessor]].name}
                      </span>
                    </div>
                  </td>
                );
              case 'expiration':
                return (
                  <td
                    className={`${column.last ? 'text-right' : ''} ${
                      index === 0 ? ' ' : ''
                    }`}
                    key={index}
                  >
                    <div className="flex  flex-col items-start gap-[6px]">
                      <span className="text-[16px] leading-[17px] font-semibold text-white font-archivo">{`${
                        days > 0 ? days + 'd ' : ''
                      }${hours + 'h'}`}</span>
                      <span className="text-[16px] leading-[17px] font-normal text-white font-archivo">
                        {date.format('DD.MM.YYYY hh:mm A')}
                      </span>
                    </div>
                  </td>
                );
              case 'textFtn':
                return (
                  <td
                    className={`${column.last ? 'text-right' : ''} ${
                      index === 0 ? ' ' : ''
                    }`}
                    key={index}
                  >
                    <span className="text-[16px] leading-[17px] font-bold text-white font-archivo">
                      {row[[column.accessor]] || '-'} FTN
                    </span>
                  </td>
                );
              default:
                return (
                  <td
                    className={`${column.last ? 'text-right pr-[30px]' : ''} ${
                      index === 0 ? 'pl-[30px]' : ''
                    }`}
                    key={index}
                  >
                    <span className="text-[16px] leading-[17px] font-bold text-white font-archivo">
                      {row[[column.accessor]]}
                    </span>
                  </td>
                );
            }
          })}
          {actions && (
            <td className={`rounded-r-[20px] pr-[16px]  w-fit`}>
              <div className="flex flex-row gap-[11px] w-full justify-end">
                {actions.map((action) => {
                  return (
                    <button
                      type="button"
                      className={`h-[60px] ${
                        action.icon ? 'w-[60px]' : 'px-[24px]'
                      }  text-[16px] font-semibold text-white font-archivo ${
                        action.active ? 'bg-[#FE7762]' : 'bg-[#0E0E0E]'
                      } rounded-[12px] flex items-center justify-center`}
                      onClick={() => {
                        action.action(row);
                      }}
                      key={action.label}
                    >
                      {action.icon}
                      {action.label}
                    </button>
                  );
                })}
              </div>
            </td>
          )}
        </tr>
      ))}
    </tbody>
  );
};

export default ConnectionsRows;
