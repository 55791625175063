import { collectionTypes } from '../types';

const getCollections = () => {
  return {
    type: collectionTypes.GET_COLLECTIONS,
  };
};
const getCollectionsSuccess = (data) => {
  return {
    type: collectionTypes.GET_COLLECTIONS_SUCCESS,
    payload: data,
  };
};
const getCollectionsError = (error) => {
  return {
    type: collectionTypes.GET_COLLECTIONS_ERROR,
    payload: error,
  };
};
const createCollection = (data) => {
  return {
    type: collectionTypes.CREATE_COLLECTION,
    payload: data,
  };
};
const createCollectionSuccess = (data) => {
  return {
    type: collectionTypes.CREATE_COLLECTION_SUCCESS,
    payload: data,
  };
};
const createCollectionError = (error) => {
  return {
    type: collectionTypes.CREATE_COLLECTION_ERROR,
    payload: error,
  };
};
const getCollectionByAddress = (address) => {
  return {
    type: collectionTypes.GET_SINGLE_COLLECTION,
    payload: address,
  };
};
const getCollectionByAddressSuccess = (data) => {
  return {
    type: collectionTypes.GET_SINGLE_COLLECTION_SUCCESS,
    payload: data,
  };
};
const getCollectionByAddressError = (error) => {
  return {
    type: collectionTypes.GET_SINGLE_COLLECTION_ERROR,
    payload: error,
  };
};

const getCollectionStatisticsByAddress = (address) => {
  return {
    type: collectionTypes.GET_COLLECTIONS_STATISTICS,
    payload: address,
  };
};
const getCollectionStatisticsByAddressSuccess = (data) => {
  return {
    type: collectionTypes.GET_COLLECTIONS_STATISTICS_SUCCESS,
    payload: data,
  };
};
const getCollectionStatisticsByAddressError = (error) => {
  return {
    type: collectionTypes.GET_COLLECTIONS_STATISTICS_ERROR,
    payload: error,
  };
};

const getNFTsByCollectionAddress = (address, minPrice, maxPrice, sortBy) => {
  return {
    type: collectionTypes.GET_COLLECTION_NFTS,
    payload: {
      address,
      minPrice,
      maxPrice,
      sortBy,
    },
  };
};
const getNFTsByCollectionAddressSuccess = (data) => {
  return {
    type: collectionTypes.GET_COLLECTION_NFTS_SUCCESS,
    payload: data,
  };
};
const getNFTsByCollectionAddressError = (error) => {
  return {
    type: collectionTypes.GET_COLLECTION_NFTS_ERROR,
    payload: error,
  };
};

const getCollectionNFTByAddressAndNFTId = (address, nftId) => {
  return {
    type: collectionTypes.GET_COLLECTION_NFT,
    payload: {
      address,
      nftId,
    },
  };
};
const getCollectionNFTByAddressAndNFTIdSuccess = (data) => {
  return {
    type: collectionTypes.GET_COLLECTION_NFT_SUCCESS,
    payload: data,
  };
};
const getCollectionNFTByAddressAndNFTIdError = (error) => {
  return {
    type: collectionTypes.GET_COLLECTION_NFT_ERROR,
    payload: error,
  };
};
const getTop5Collections = () => {
  return {
    type: collectionTypes.GET_TOP_5_COLLECTIONS,
  };
};
const getCollectionList = (minPrice, maxPrice, sortBy, listed) => {
  return {
    type: collectionTypes.GET_COLLECTION_LIST,
    payload: { minPrice, maxPrice, sortBy, listed },
  };
};
const getNFTList = (sortBy, minPrice, maxPrice, search, listed) => {
  return {
    type: collectionTypes.GET_NFT_LIST,
    payload: { minPrice, maxPrice, sortBy, search, listed },
  };
};
export {
  getCollections,
  getCollectionsSuccess,
  getCollectionsError,
  createCollection,
  createCollectionSuccess,
  createCollectionError,
  getCollectionByAddress,
  getCollectionByAddressSuccess,
  getCollectionByAddressError,
  getCollectionStatisticsByAddress,
  getCollectionStatisticsByAddressSuccess,
  getCollectionStatisticsByAddressError,
  getNFTsByCollectionAddress,
  getNFTsByCollectionAddressSuccess,
  getNFTsByCollectionAddressError,
  getCollectionNFTByAddressAndNFTId,
  getCollectionNFTByAddressAndNFTIdSuccess,
  getCollectionNFTByAddressAndNFTIdError,
  getTop5Collections,
  getCollectionList,
  getNFTList,
};
