import { useRouter } from 'next/router';
import Table from '../Table/Table';

export const TopCollections = ({ collections, loading }) => {
  const router = useRouter();
  const handleRowClick = (data) => {
    router.push(`/collections/${data.collection_address}`);
  };
  return (
    <div>
      <p className="font-archivo text-[60px] leading-[66px] text-white uppercase">
        Top Collections in 7 days
      </p>
      <div className="flex-1 rounded-[20px] border-[1px] border-[#FFFFFF33] px-[0px] mt-[40px] bg-[#0E0E0E]">
        <Table
          loading={loading}
          placeholder="On maintenance..."
          mode="collections"
          columns={[
            {
              header: '#',
              accessor: 'rank',
              type: 'text',
            },
            {
              header: 'Collection',
              accessor: 'collection',
              type: 'user',
            },
            {
              header: 'Floor Price',
              accessor: 'floor_price',
              type: 'textFtn',
            },
            {
              header: '7d Volume',
              accessor: 'seven_day_volume',
              type: 'textFtn',
            },
            {
              header: 'Volume Change',
              accessor: 'day_volume_change',
              type: 'percent',
            },
            {
              header: 'Items',
              accessor: 'items',
              type: 'text',
            },
            {
              header: 'Owners',
              accessor: 'owners',
              type: 'text',
              last: true,
            },
          ]}
          data={collections}
          onRowClick={handleRowClick}
        />
      </div>
    </div>
  );
};
