import { useState, useEffect } from 'react';

const Countdown = ({ timestamp, hourOnly }) => {
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => setTimeLeft(timestamp - Date.now()), [timestamp]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(timestamp - Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, [timestamp]);

  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

  return Date.now() > timestamp ? (
    '00:00:00'
  ) : (
    <div>
      {hourOnly
        ? `${days}d ${hours}h`
        : `${days}d ${hours}h ${minutes}m ${seconds}s`}
    </div>
  );
};

export default Countdown;
