import moment from 'moment';

const BidsRows = ({ rows, columns, actions }) => {
  return (
    <tbody>
      {rows.map((row, index) => (
        <tr key={index} className="cursor-pointer">
          {columns.map((column, index) => {
            const date = moment(Number(row[[column.accessor]]));
            switch (column.type) {
              case 'user':
                return (
                  <td
                    className={`${column.last ? 'rounded-r-[20px] ' : ''} ${
                      index === 0 ? 'rounded-l-[20px] p-[16px] ' : 'py-[16px]'
                    } bg-[#1D1F24]`}
                    key={index}
                  >
                    <div className="flex flex-row gap-[22px] items-center">
                      <img
                        src={row[[column.accessor]].image}
                        className="w-[60px] h-[60px] rounded-[12px]"
                        alt=""
                      />
                      <span className="text-[18px] leading-[19px] font-bold text-white font-archivo">
                        {row[[column.accessor]].name}
                      </span>
                    </div>
                  </td>
                );
              case 'expiration':
                return (
                  <td
                    className={`${column.last ? 'rounded-r-[20px] ' : ''} ${
                      index === 0 ? 'rounded-l-[20px] p-[16px] ' : 'py-[16px]'
                    } bg-[#1D1F24]`}
                    key={index}
                  >
                    <div className="flex  flex-col items-start gap-[6px]">
                      <span className="text-[18px] leading-[19px] font-semibold text-white font-archivo">
                        {date.fromNow()}
                      </span>
                      <span className="text-[18px] leading-[19px] font-normal text-white font-archivo">
                        {date.format('DD.MM.YYYY hh:mm A')}
                      </span>
                    </div>
                  </td>
                );
              default:
                return (
                  <td
                    className={`${
                      column.last ? 'rounded-r-[20px] pr-[16px] ' : ''
                    } ${
                      index === 0 ? 'rounded-l-[20px] p-[16px] ' : 'py-[16px]'
                    } bg-[#1D1F24]`}
                    key={index}
                  >
                    <span className="text-[18px] leading-[19px] font-bold text-white font-archivo">
                      {row[[column.accessor]]}
                    </span>
                  </td>
                );
            }
          })}
          {actions && (
            <td className={`rounded-r-[20px] px-[16px] bg-[#1D1F24] w-fit`}>
              <div className="flex flex-row gap-[11px] w-full justify-start">
                {actions.map((action) => {
                  if (action.isShow && !action.isShow(row)) {
                    return null;
                  }
                  return (
                    <button
                      type="button"
                      className={`h-[60px] ${
                        action.icon ? 'w-[60px]' : 'px-[24px]'
                      }  text-[16px] font-semibold text-white font-archivo ${
                        action.active ? 'bg-[#FE7762]' : 'bg-[#0E0E0E]'
                      } rounded-[12px] flex items-center justify-center`}
                      onClick={() => {
                        action.action(row);
                      }}
                      key={action.label}
                    >
                      {action.icon}
                      {action.label}
                    </button>
                  );
                })}
              </div>
            </td>
          )}
        </tr>
      ))}
    </tbody>
  );
};

export default BidsRows;
